import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "grid grid-cols-1 sm:grid-cols-2 w-full" }
const _hoisted_2 = { class: "bg-white flex flex-col items-center sm:items-start sm:justify-center pt-20 sm:pt-0 w-full px-8 lg:px-28 xl:px-40" }
const _hoisted_3 = {
  key: 0,
  class: "mb-8 text-2xl font-semibold text-gray-700"
}
const _hoisted_4 = { class: "mb-3 text-2xl font-semibold text-gray-700" }
const _hoisted_5 = { class: "text-sm mb-8" }
const _hoisted_6 = { class: "mb-3 text-2xl font-semibold text-gray-700" }
const _hoisted_7 = { class: "text-sm mb-8" }
const _hoisted_8 = { class: "flex items-center justify-between" }
const _hoisted_9 = { class: "text-sm" }
const _hoisted_10 = { class: "block" }
const _hoisted_11 = { class: "block" }
const _hoisted_12 = { key: 5 }
const _hoisted_13 = { class: "block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", { class: "bg-primary hidden sm:flex items-center justify-center w-full relative" }, [
      _createElementVNode("img", {
        src: $setup.imageLoginPath,
        class: "h-[150px] xl:h-[250px]",
        alt: "logo Mobcall"
      })
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        class: "mb-8 w-[225px]",
        src: $setup.logoPath,
        alt: "logo Mobcall"
      }),
      ($setup.showFormType === $setup.FormType.LOGIN)
        ? (_openBlock(), _createElementBlock("h3", _hoisted_3, _toDisplayString(_ctx.$t("welcome")), 1 /* TEXT */))
        : _createCommentVNode("v-if", true),
      ($setup.showFormType === $setup.FormType.RECOVER)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.$t("forgotPassword.title")), 1 /* TEXT */),
            _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t("forgotPassword.description")), 1 /* TEXT */)
          ], 64 /* STABLE_FRAGMENT */))
        : _createCommentVNode("v-if", true),
      ($setup.showFormType === $setup.FormType.SENT_RECOVER)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
            _createElementVNode("h3", _hoisted_6, _toDisplayString(_ctx.$t("forgotPassword.requested.title")), 1 /* TEXT */),
            _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.$t("forgotPassword.requested.description")), 1 /* TEXT */)
          ], 64 /* STABLE_FRAGMENT */))
        : _createCommentVNode("v-if", true),
      ($setup.showFormType === $setup.FormType.LOGIN)
        ? (_openBlock(), _createElementBlock("form", {
            key: 3,
            class: "space-y-6 w-full",
            onSubmit: _withModifiers($setup.onSubmitLogin, ["prevent"])
          }, [
            _createVNode($setup["UiTextInput"], {
              modelValue: $setup.formState.email,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.formState.email) = $event)),
              name: "email",
              type: "text",
              label: _ctx.$t('login.form.emailAddress'),
              class: "",
              inputClass: "text-base px-4 py-3",
              autocomplete: "email",
              errors: $setup.v$.email.$errors,
              onBlur: $setup.v$.email.$touch
            }, null, 8 /* PROPS */, ["modelValue", "label", "errors", "onBlur"]),
            _createVNode($setup["UiTextInput"], {
              modelValue: $setup.formState.password,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.formState.password) = $event)),
              name: "password",
              type: "password",
              label: _ctx.$t('login.form.password'),
              class: "",
              inputClass: "text-base px-4 py-3",
              autocomplete: "password",
              errors: $setup.v$.password?.$errors,
              onBlur: $setup.v$.password?.$touch
            }, null, 8 /* PROPS */, ["modelValue", "label", "errors", "onBlur"]),
            _createElementVNode("div", _hoisted_8, [
              _createVNode($setup["UiCheckboxInput"], {
                modelValue: $setup.formState.rememberMe,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.formState.rememberMe) = $event)),
                name: "rememberMe",
                label: _ctx.$t('login.form.rememberMe'),
                class: "h-6"
              }, null, 8 /* PROPS */, ["modelValue", "label"]),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("span", {
                  onClick: _cache[3] || (_cache[3] = ($event: any) => ($setup.showFormType = $setup.FormType.RECOVER)),
                  class: "text-sm font-medium text-primary hover:text-primary-darker cursor-pointer"
                }, _toDisplayString(_ctx.$t("login.form.forgotYourPassword")), 1 /* TEXT */)
              ])
            ]),
            _createElementVNode("div", null, [
              _createVNode($setup["UiButton"], {
                type: "submit",
                themed: "",
                variant: "primary",
                class: "w-full text-lg shadow",
                size: "xl"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.$t("login.form.signIn")), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              })
            ])
          ], 32 /* NEED_HYDRATION */))
        : _createCommentVNode("v-if", true),
      ($setup.showFormType === $setup.FormType.RECOVER)
        ? (_openBlock(), _createElementBlock("form", {
            key: 4,
            class: "space-y-6 w-full",
            onSubmit: _withModifiers($setup.onSubmitResetPassword, ["prevent"])
          }, [
            _createVNode($setup["UiTextInput"], {
              modelValue: $setup.formState.email,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.formState.email) = $event)),
              name: "email",
              type: "text",
              label: _ctx.$t('forgotPassword.form.emailAddress'),
              class: "",
              inputClass: "text-base px-4 py-3",
              autocomplete: "email",
              errors: $setup.v$.email.$errors,
              onBlur: $setup.v$.email.$touch
            }, null, 8 /* PROPS */, ["modelValue", "label", "errors", "onBlur"]),
            _createElementVNode("div", null, [
              _createVNode($setup["UiButton"], {
                type: "submit",
                themed: "",
                variant: "primary",
                class: "w-full text-lg shadow",
                size: "xl"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.$t("forgotPassword.form.resetPass")), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              })
            ])
          ], 32 /* NEED_HYDRATION */))
        : _createCommentVNode("v-if", true),
      ($setup.showFormType === $setup.FormType.SENT_RECOVER)
        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
            _createVNode($setup["UiButton"], {
              onClick: _cache[5] || (_cache[5] = ($event: any) => ($setup.showFormType = $setup.FormType.LOGIN)),
              themed: "",
              variant: "primary",
              class: "w-full text-lg shadow",
              size: "xl"
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.$t("forgotPassword.requested.action")), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            })
          ]))
        : _createCommentVNode("v-if", true)
    ])
  ]))
}