import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, createElementVNode as _createElementVNode, renderSlot as _renderSlot, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = { class: "flex flex-col items-center" }
const _hoisted_2 = { class: "flex w-full gap-2 justify-end relative" }
const _hoisted_3 = {
  key: 0,
  class: "p-1 absolute -top-[45px] -left-[45px] bg-emerald-50 border border-gray-300 rounded-full shadow z-50"
}
const _hoisted_4 = {
  key: 1,
  class: "flex-1 text-gray-900 text-md font-medium"
}
const _hoisted_5 = { class: "text-sm leading-8" }
const _hoisted_6 = {
  key: 0,
  class: "mt-3"
}
const _hoisted_7 = { class: "bg-gray-50 px-1.5 py-0.5 text-xs rounded-md ring-1 ring-black ring-opacity-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _mergeProps(_ctx.$attrs, {
      class: [
        $props.bgColor ? $props.bgColor : 'bg-white',
        $props.borderColor ? $props.borderColor : 'border-gray-300',
        $props.roundedStyle ? $props.roundedStyle : 'rounded-xl',
        $props.padding ? $props.padding : 'px-5 py-5',
        $props.nodeWidth ? $props.nodeWidth : 'w-[350px]',
        $props.nodeHeight ? $props.nodeHeight : 'h-auto',
        'border-2 shadow relative',
      ]
    }), [
      _createElementVNode("div", _hoisted_2, [
        ($props.icon)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              (_openBlock(), _createBlock(_resolveDynamicComponent($props.icon), {
                class: "w-9 h-9 text-custom-two",
                "aria-hidden": "true"
              }))
            ]))
          : _createCommentVNode("v-if", true),
        ($props.title)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString($props.title), 1 /* TEXT */))
          : _createCommentVNode("v-if", true),
        ($props.showActions)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
              (!$props.noEdition)
                ? (_openBlock(), _createBlock($setup["UiButton"], {
                    key: 0,
                    variant: "primary",
                    "text-variant": "gray",
                    size: "xs",
                    class: "h-6",
                    onClick: $setup.handleEditClick
                  }, {
                    default: _withCtx(() => [
                      _createVNode($setup["PencilAltIcon"], { class: "h-4 w-4" })
                    ]),
                    _: 1 /* STABLE */
                  }))
                : _createCommentVNode("v-if", true),
              _createVNode($setup["UiButton"], {
                variant: "primary",
                "text-variant": "gray",
                size: "xs",
                class: "h-6",
                onClick: $setup.handleRemoveClick
              }, {
                default: _withCtx(() => [
                  _createVNode($setup["TrashIcon"], { class: "h-4 w-4" })
                ]),
                _: 1 /* STABLE */
              })
            ], 64 /* STABLE_FRAGMENT */))
          : _createCommentVNode("v-if", true)
      ]),
      ($props.showHandleTarget)
        ? (_openBlock(), _createBlock($setup["Handle"], {
            key: 0,
            type: "target",
            position: $setup.Position.Top
          }, null, 8 /* PROPS */, ["position"]))
        : _createCommentVNode("v-if", true),
      ($props.showHandleSource)
        ? (_openBlock(), _createBlock($setup["Handle"], {
            key: 1,
            type: "source",
            position: $setup.Position.Bottom
          }, null, 8 /* PROPS */, ["position"]))
        : _createCommentVNode("v-if", true),
      _createElementVNode("div", _hoisted_5, [
        _renderSlot(_ctx.$slots, "default"),
        (!$props.hideNodeId)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("span", _hoisted_7, " #" + _toDisplayString($setup.node.id), 1 /* TEXT */)
            ]))
          : _createCommentVNode("v-if", true)
      ])
    ], 16 /* FULL_PROPS */),
    _createVNode($setup["NodeToolbar"], {
      class: "flex gap-0.5 items-center",
      "is-visible": $setup.node?.connectable as boolean,
      position: $setup.Position.Bottom
    }, {
      default: _withCtx(() => [
        _createElementVNode("button", {
          class: "rounded-full p-1 border-1 shadow bg-white hover:shadow-blueGray-600",
          onClick: $setup.openComponentSelector
        }, [
          _createVNode($setup["PlusIcon"], { class: "h-5 w-5" })
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["is-visible", "position"])
  ]))
}